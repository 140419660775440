<template>
    <div class="Detail">
        <Empty description="努力开发中，敬请期待..." />
        <!--
        <BrandList v-on:clickBrand="clickBrand"></BrandList>
        <div class="branditem">
            <div class="branditem_hd">
                <div class="picbg">
                    <VanImage width="100%" height="auto" lazy-load :src="require('@assets/images/brand/brandtitbg.png')" />
                </div>
                <div class="pic" v-if="info.brand">
                    <VanImage width="100%" height="auto" lazy-load :src="info.brand.logo" />
                </div>
                <div class="goodslist">
                    <div class="dpic" v-for="(item, index) in goodsList" :key="index" @click="product()">
                        <VanImage width="100%" height="100%" lazy-load fit="cover" :src="item.wcj_images" />
                    </div>
                </div>
            </div>
            <div class="branditem_bd">
                <div class="dpic">
                    <VanImage width="100%" height="100%" lazy-load fit="cover" src="http://www.tuoers.cn/uploads/attach/2020/10/20201022/e08b88382b61c243f0aba9d476b627ca.png" />
                </div>
                <div class="hd">
                    <div class="tit">十二星座-法国射手座</div>
                    <div class="htit">
                        <div class="desc Ellipses1">法国 | 波尔多 | 中级庄 | 50%赤霞珠，50%梅洛 | 13.5%</div>
                    </div>
                    <div class="htit">
                        <div class="Rate">
                            <span class="Rate_num">4.2</span>
                            <Rate v-model="4.2" allow-half :size="25" color="#ec652b" void-icon="star" void-color="#eee"/>
                        </div>
                        <div class="tiao Ellipses1">1.7万条</div>
                    </div>
                </div>
            </div>
        </div>-->
        <!--专业级品鉴统计数据-->
        <!--<div class="count">
            <div class="hd acea-row row-between-wrapper">
                <div class="tit">品鉴统计数据</div>
                <div>
                    全国<span class="font-color">1.2万条评论数据</span>
                </div>
            </div>
            <div class="count_tb">
                <Row>
                    <Col span="4">颜 色:</Col>
                    <Col span="4">中+</Col>
                    <Col span="4" class="cur-color">中58</Col>
                    <Col span="4">中48</Col>
                    <Col span="4">中00</Col>
                    <Col span="4">中00</Col>
                </Row>
                <Row>
                    <Col span="4">颜 色:</Col>
                    <Col span="4">中+</Col>
                    <Col span="4" class="cur-color">中58</Col>
                    <Col span="4">中48</Col>
                    <Col span="4">中00</Col>
                    <Col span="4">中00</Col>
                </Row>
                <Row>
                    <Col span="4">颜 色:</Col>
                    <Col span="4">中+</Col>
                    <Col span="4" class="cur-color">中58</Col>
                    <Col span="4">中48</Col>
                    <Col span="4">中00</Col>
                    <Col span="4">中00</Col>
                </Row>
                <Row>
                    <Col span="4">颜 色:</Col>
                    <Col span="4">中+</Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                </Row>
                <Row>
                    <Col span="4">颜 色:</Col>
                    <Col span="4">中+</Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                </Row>
                <Row>
                    <Col span="4">颜 色:</Col>
                    <Col span="4">中+</Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                </Row>
                <Row>
                    <Col span="4">颜 色:</Col>
                    <Col span="4">中+</Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                </Row>
            </div>
        </div>-->
        <!--专业级品鉴-->
        <!--<div class="comment">
            <div class="tit">
                <h1>专业级品鉴</h1>
            </div>
            <div class="con">
                <div class="comment_item">
                    <div class="hd">
                        <div class="userpic">
                            <VanImage width="100%" height="100%" fit="cover" lazy-load src="" />
                        </div>
                        <div class="rgcon">
                            <div class="rghd acea-row row-between-wrapper">
                                <div>
                                    <div class="name Ellipses1">大圣999</div>
                                    <div class="grade">
                                        <span class="bg-color">酒徒</span>
                                        <div class="zhi">
                                            <div>
                                                <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                                <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                                <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                                <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                                <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                            </div>
                                        </div>
                                        <span class="font-color">酒量值：220</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="add font-color border-color">
                            <Icon name="plus">关注</Icon>
                        </div>
                    </div>
                    <div class="bd">
                        <div class="bdh1">塔鹿-彩鹿赤霞珠</div>
                        <div class="bdh2">智利 | 中央山谷 | 精选级 | 赤霞珠 | 13.5% | 2019</div>
                        <div class="count_tb">
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4" class="cur-color">中58</Col>
                                <Col span="4">中48</Col>
                                <Col span="4">中00</Col>
                                <Col span="4">中00</Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4" class="cur-color">中58</Col>
                                <Col span="4">中48</Col>
                                <Col span="4">中00</Col>
                                <Col span="4">中00</Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4" class="cur-color">中58</Col>
                                <Col span="4">中48</Col>
                                <Col span="4">中00</Col>
                                <Col span="4">中00</Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                            </Row>
                        </div>
                        <div class="bdh1">嗅觉-香气特征</div>
                        <div class="bdh3">
                            <span>一类香气：</span>
                            苺和桑椹果香为主，加上细微的薄荷巧克力气息...
                        </div>
                        <div class="bdh3">
                            <span>二类香气：</span>
                            苺和桑椹果香为主，加上细微的薄荷巧克力气息...
                        </div>
                        <div class="bdh3">
                            <span>三类香气：</span>
                            苺和桑椹果香为主，加上细微的薄荷巧克力气息...
                        </div>
                    </div>
                    <div class="fd acea-row row-between-wrapper">
                        <div>08-24 广州市</div>
                        <div class="rgfdicon">
                            <Icon name="like-o">8</Icon>
                            <Icon name="chat-o">8</Icon>
                            <Icon name="contact">8</Icon>
                        </div>
                    </div>
                </div>
                <div class="comment_item">
                    <div class="hd">
                        <div class="userpic">
                            <VanImage width="100%" height="100%" fit="cover" lazy-load src="" />
                        </div>
                        <div class="rgcon">
                            <div class="rghd acea-row row-between-wrapper">
                                <div>
                                    <div class="name Ellipses1">大圣999</div>
                                    <div class="grade">
                                        <span class="bg-color">酒徒</span>
                                        <div class="zhi">
                                            <div>
                                                <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                                <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                                <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                                <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                                <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                            </div>
                                        </div>
                                        <span class="font-color">酒量值：220</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="add">
                            <Icon name="">已关注</Icon>
                        </div>
                    </div>
                    <div class="bd">
                        <div class="bdh1">塔鹿-彩鹿赤霞珠</div>
                        <div class="bdh2">智利 | 中央山谷 | 精选级 | 赤霞珠 | 13.5% | 2019</div>
                        <div class="count_tb">
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4" class="cur-color">中58</Col>
                                <Col span="4">中48</Col>
                                <Col span="4">中00</Col>
                                <Col span="4">中00</Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4" class="cur-color">中58</Col>
                                <Col span="4">中48</Col>
                                <Col span="4">中00</Col>
                                <Col span="4">中00</Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4" class="cur-color">中58</Col>
                                <Col span="4">中48</Col>
                                <Col span="4">中00</Col>
                                <Col span="4">中00</Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                            </Row>
                        </div>
                        <div class="bdh1">嗅觉-香气特征</div>
                        <div class="bdh3">
                            <span>一类香气：</span>
                            苺和桑椹果香为主，加上细微的薄荷巧克力气息...
                        </div>
                        <div class="bdh3">
                            <span>二类香气：</span>
                            苺和桑椹果香为主，加上细微的薄荷巧克力气息...
                        </div>
                        <div class="bdh3">
                            <span>三类香气：</span>
                            苺和桑椹果香为主，加上细微的薄荷巧克力气息...
                        </div>
                    </div>
                    <div class="fd acea-row row-between-wrapper">
                        <div>08-24 广州市</div>
                        <div class="rgfdicon">
                            <Icon name="like-o">8</Icon>
                            <Icon name="chat-o">8</Icon>
                            <Icon name="contact">8</Icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Backtop></Backtop>-->
    </div>
</template>

<script>
    import BrandList from "./../components/brandList";
    import Backtop from "./../components/Backtop";
    import { ProduceComplex, getBrand, getmenu } from "@api/tasting";
    import { Icon, Col, Row, Empty, List, Toast, Tab, Tabs, Swipe, SwipeItem, Rate } from "vant";
    import { Image as VanImage } from 'vant';
    export default {
        name: "Detail",
        data () {
            return {
                id: '',
                activeName: '',
                brandList: [],
                goodsList: [], //单瓶列表
                goodsList2: [
                    'http://www.tuoers.cn/uploads/attach/2020/10/20201022/9431eccd0a45c4e42ad1609b90194758.png',
                    'http://store.oupuhui.com/uploads/attach/2020/09/20200908/aeb1d6e66bba25ba05d87863de188a0b.jpg',
                ], //商品列表
                current: 0,
                SwipeList: [
                    '',
                    'https://store.oupuhui.com/uploads/store/comment/20200924/f8c28e50b0f3a0eeb3fc888d20525c4c.jpg',
                    'https://store.oupuhui.com/uploads/store/comment/20200924/f8c28e50b0f3a0eeb3fc888d20525c4c.jpg',
                ],
                Slide: [],
                swiperOption: {
                    loop: true, //是否循环
                },
                info: '',
                activeName2: 'a',

                loading: false,
                finished: false,
            }
        },
        components: {
            BrandList, Icon, Col, Row, Empty, List, VanImage, Backtop, Tab, Tabs, Swipe, SwipeItem, Rate
        },
        created() {
            console.log(this.$route.query.id)
            if(this.$route.query.id){
                this.id=this.$route.query.id;
            }else{
                this.id='1';
            }
        },
        mounted: function() {
            // this.getProduceComplex();
            // this.getBranddata();
        },
        methods: {
            goPages: function(url) {
                this.$router.push({ path: url });
            },
            clickBrand: function () {
                // this.$router.push({ path: '/Tasting/wine/info?id=1' });
            },
            getProduceComplex: function() {
                Toast.loading({
                    duration: 0,
                    message: '加载中...',
                    forbidClick: true,
                });
                ProduceComplex({
                    id: this.id
                }).then(res => {
                    Toast.clear();
                    this.activeName=res.data.brand.id;
                    this.info=res.data;
                    this.getmenu(this.activeName);
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            getmenu: function(id) {
                getmenu({
                    id:id,
                    level:3,
                }).then(res => {
                    this.goodsList=res.data;
                })
            },
            
            getBranddata: function() {
                getBrand().then(res => {
                    this.brandList=res.data;
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            changetab: function() {
                console.log(this.activeName)
            },

            onLoad() {
                console.log(8888888888888)
            }
        },
    }
</script>

<style scoped lang="scss">
::v-deep{
    .van-icon-star{font-size: 0.32rem!important;}
    .van-rate__item{padding-right: 0.05rem;}
}
::v-deep {
    .brandList{
        .van-tab{padding:0.3rem 0.1rem;}
        .van-tabs__wrap {
            height: 1.2rem;
        }
        .van-tabs__line{background:none;}
        .van-tabs__line::before{
            background:#f4f4f4;
            border-left: 1px solid #b6b6b6;
            border-top: 1px solid #b6b6b6;
            width: 0.2rem;
            height: 0.2rem;
            display: block;
            content: '';
            margin: 0 auto;transform: rotate(45deg);
        }
    }
    .van-swipe__indicator{width: 0.18rem;height:0.18rem;background-color:#a2a2a2;}
    .info{
        .van-tabs__line{background:#000;}
    }
}
.hd{
    img{width: 100%;}
}
.brandList{
    border-bottom:1px solid #eee;
    .brandpic{
        border: 1px solid #4c4744;border-radius: 0.1rem;overflow: hidden;height: 0.7rem;width: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        img{width: auto;height: 100%;}
    }
}
.branditem{
    background:#fff;overflow: hidden;
    .branditem_hd{
        height:1.2rem;background:#f4f4f4;margin-bottom: 0.8rem;position:relative;
        .pic{position:absolute;left: 0.2rem;width:1.82rem;height:1.6rem;border:1px solid #e8e8e8;overflow:hidden;border-radius:0.1rem;z-index:2;}
        .goodslist{
            position:absolute;right: 0;overflow-x: scroll;max-width:5.4rem;white-space: nowrap;z-index:2;
            .dpic{margin: 0.2rem 0.2rem 0;width: 0.46rem;height:1.5rem;display: inline-block;}
        }
        .picbg{position:absolute;left: 1.84rem;right: 0;z-index:1;}
    }
    .branditem_bd{
        position:relative;padding-bottom: 0.2rem;
        .dpic{
            width: 0.6rem;float:left;margin: 0 0.5rem;
        }
        .hd{
            padding: 0.2rem 0.2rem 0 1.7rem;
            .tit{font-size: 0.28rem;}
            .htit{
                font-size: 0.24rem;color:#999;line-height: 0.4rem;overflow:hidden;
                .desc{}
                .Rate{
                    float:left;display: flex;
                    .Rate_num{color:#ec652b;font-size: 0.36rem;margin-right:0.05rem;}
                    .van-rate{padding-top:0.04rem;}
                }
                .tiao{float:left;color:#333;margin-left: 0.05rem;}
            }
            .commentnav{
                font-size: 0.2rem;overflow:hidden;margin: 0.1rem -0.1rem 0;
                a{
                    display: inline-block;float: left;margin: 0.05rem;border: 1px solid #eee;line-height: 0.5rem;padding: 0 0.1rem;border-radius: 0.1rem;
                }
                
            }
        }
    }
}

.Winery{
    margin-top: 0.2rem;
    .Wineryitem{
        margin-bottom: 0.2rem;
        .tit{padding: 0.1rem 0;background:#fff;}
        .con{
            background:#fff;
        }
    }
}

.count_tb{
    border-top: 1px solid #eee;border-bottom: 1px solid #eee;background:#f3f3f3;
    .van-row{
        border-top: 1px solid #fff;
        .van-col{text-align: center;font-size:0.24rem;line-height: 0.5rem;border-left: 1px solid #fff;min-height: 0.5rem;}
        .van-col:first-child{background:#e8e8e8;border-left: none;}
        .cur-color{background: #ffd5d6;}
    }
    .van-row:first-child{border-top: none;}
}
.count{
    .hd{
        padding: 0.2rem;color:#999;
        .tit{font-size:0.32rem;}
    }
}
.comment{
    margin-top: 0.4rem;background:#fff;border-top-left-radius: 0.2rem;border-top-right-radius: 0.2rem;
    .tit{
        border-bottom: 1px solid #eee;padding: 0.2rem 0.1rem;
        h1{text-align: center;font-size: 0.36rem;}
        .commentnav{
            font-size: 0.24rem;overflow:hidden;margin-top: 0.1rem;
            a{
                display: inline-block;float: left;margin: 0.1rem;border: 1px solid #eee;line-height: 0.5rem;padding: 0 0.1rem;border-radius: 0.1rem;
            }
            
        }
    }
    .comment_item{
        border-bottom: 1px solid #eee;padding: 0.2rem;font-size: 0.24rem;
        .hd{
            overflow:hidden;position:relative;
            .userpic{float:left;width:1rem;height:1rem;display:block;overflow:hidden;border-radius: 100%;}
            .rgcon{
                padding-left: 1.15rem;color: #999;
                .rghd{
                    height:1rem;
                    .name{max-width: 3rem;font-size: 0.28rem;color:#000;}
                    .grade{
                        margin-top: 0.05rem;
                        span{color:#fff;line-height: 0.4rem;display:block;float:left;padding: 0 0.05rem;border-radius:0.08rem;}
                        span:first-child{padding: 0 0.2rem;}
                        .zhi{
                            float:left;text-align: right;margin: 0 0.05rem 0 0.2rem;
                            .van-image{margin-left:-0.12rem;}
                        }
                    }
                    .zhi{text-align: right;}
                }
                .goodsname{font-size: 0.28rem;}
                .pingfen{padding: 0.1rem 0;}
                .label{
                    overflow:hidden;
                    span{display:block;float:left;margin-right:0.2rem;}
                }
                .chosen{
                    color:#333;line-height:0.4rem;padding: 0.1rem 0;
                    span{padding: 0.02rem 0.1rem;border-radius: 0.08rem;}
                }
                .bpic{
                    overflow:hidden;border-radius:0.4rem;
                    .bpic_dd{
                        width:33.333%;padding:0.05rem;float:left;
                        .van-image{}
                    }
                    .bpic_dd1{width:100%!important;}
                    .bpic_dd4{width:50%!important;}
                }
                .sharegoods{
                    background:#f3f3f5;padding: 0.1rem;overflow:hidden;
                    .sharegoods_pic{float:left;width: 1rem;height:1rem;}
                    .sharegoods_con{
                        padding-left: 1.2rem;padding-top:0.1rem;
                        .dd{line-height:0.3rem;color:#333;}
                    }
                }
                .rgfd{
                    padding-top: 0.1rem;
                    .rgfdicon{
                        .van-icon{float:left;margin-left: 0.15rem;}
                        .van-icon::before{font-size: 0.28rem;margin-right: 0.05rem;float:left;}
                    }
                }
            }
            .add{
                position: absolute;top:0.24rem;right:0;display:block;font-size:0.24rem;padding:0.1rem;border-radius:0.1rem;border: 1px solid #999;color:#999;
                i{display: flex;}
                i::before{font-size:0.28rem;margin-right:0.05rem;}
            }
        }
        .bd{
            .bdh1{font-size:0.28rem;color:#333;line-height: 0.5rem;}
            .bdh2{color: #999;margin-bottom:0.2rem;}
            .bdh3{
                color: #999;margin-bottom:0.1rem;
                span{color:#333;}
            }
        }
        .fd{
            padding-top: 0.1rem;
            .rgfdicon{
                .van-icon{float:left;margin-left: 0.15rem;}
                .van-icon::before{font-size: 0.28rem;margin-right: 0.05rem;float:left;}
            }
        }
    }
    .more{text-align: center;line-height:0.8rem;}
}
</style>